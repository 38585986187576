import axios from '@/js/axios.js'

export default {
  fetchSiteVisitsTotal ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/analytics/reporting/site-visits-total', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchSiteVisits ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/analytics/reporting/site-visits', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  timeintervals ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/analytics/event/time-intervals', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
}