<template>
  <b-alert
    class="mt-1"
    :show="show"
    :variant="(success)? 'success' : 'danger'"
    dismissible
    fade
    @dismissed="dismissed()">
    {{ message }}
  </b-alert>
</template>

<script>
export default {
  name: "UserFeedback",
  data () {
    return {
      message: null,
      success: null,
      show:false,
      show_default:4
    }
  },
  mounted: function () {
    this.$root.$on('userFeedback', data => {
      this.$data.show = this.$data.show_default;
      Object.assign(this.$data, data);
    });
  },
  methods: {
    dismissed(){
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>