import passport from '../../http/requests/auth/passport/index.js'

import router from '@/js/router'
import store from "@/js/store/store";
import axios from "@/js/axios";

export default {

    bounceUser(payload) {
        store._vm.$root.$emit("userFeedback", {
            success: false,
            message: "You do not have permission to access this content"
        });
        let route = {path: '/login'};
        if(payload.path) route.query = {to: payload.path};
        router.push(route).catch(() => {});
    },
    loginSaml({commit}, payload) {
        payload.from = 'monitor'
        return new Promise((resolve, reject) => {
            axios({
                url: '/auth/login/saml',
                method: 'POST',
                data: payload
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    samlCallback({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/saml/${payload.account}/callback`,
                method: 'POST',
                data: payload,
            })
            .then((response) => {
                if (response.data.userData) {
                    commit('SET_ACCESS_TOKEN', {token: response.data.accessToken, expiry: response.data.expires_at});
                    commit('UPDATE_USER_INFO', response.data.userData)
                    commit('SET_BEARER', response.data.accessToken)
                    resolve(response)
                } else {
                    resolve(response)
                }
            })
            .catch((error) => {
                reject(error)
            })
        }
    )},
    // Passport
    loginPassport({commit}, payload) {
        return new Promise((resolve, reject) => {
            passport.login(payload.email, payload.password)
            .then(response => {

                // If there's user data in response
                if (response.data.userData) {

                    commit('SET_ACCESS_TOKEN', {token: response.data.accessToken, expiry: response.data.expires_at});

                    // Update user details
                    commit('UPDATE_USER_INFO', response.data.userData)

                    // Set bearer token in axios
                    commit('SET_BEARER', response.data.accessToken)

                    resolve(response)

                } else {

                    resolve(response)

                }

            })
            .catch(error => {
                reject(error)
            })
        })
    },
    logoutPassport({commit}) {
        return new Promise((resolve, reject) => {
            passport.logout()
            .then(response => {
                // Update user details
                commit('DELETE_USER')
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    registerUserPassport({commit}, payload) {

        const {displayName, email, password, confirmPassword} = payload.userDetails

        return new Promise((resolve, reject) => {

            // Check confirm password
            if (password !== confirmPassword) {
                reject({message: 'Password doesn\'t match. Please try again.'})
            }

            passport.registerUser(displayName, email, password)
            .then(response => {
                // Redirect User
                router.push(router.currentRoute.query.to || '/')

                // Set bearer token in axios
                commit('SET_BEARER', response.data.accessToken)
                commit('UPDATE_USER_INFO', response.data.userData)

                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    fetchAccessToken({commit}, payload) {
        return new Promise((resolve) => {
            passport.refreshToken().then(response => {
                if (response.data.accessToken) {
                    commit('SET_ACCESS_TOKEN', {token: response.data.accessToken, expiry: response.data.expires_at});
                    // Set bearer token in axios
                    commit('SET_BEARER', response.data.accessToken)
                }
                resolve(response)
            })
        })
    }
}
