import state from './moduleAnalyticsReportingState.js'
import mutations from './moduleAnalyticsReportingMutations.js'
import actions from './moduleAnalyticsReportingActions.js'
import getters from './moduleAnalyticsReportingGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
