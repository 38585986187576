<template>
  <b-nav
    v-if="route.meta.visible"
    v-can="route.meta.acl"
    vertical class="d-block">
    <div>

      <b-button v-if="route.children"
                variant="transparent" class="mb-1 text-left d-flex justify-content-between align-items-center w-100"
                v-b-toggle="`acc-${route.meta.slug}`">{{ route.meta.display_name }}
        <b-icon v-if="route.children" class="when-open ml-auto" icon="chevron-down" font-scale=".9"></b-icon>
        <b-icon v-if="route.children" class="when-closed ml-auto" icon="chevron-right" font-scale=".9"></b-icon>
      </b-button>
      <b-button v-else
                variant="transparent"
                class="mb-1 text-left w-100"
                :to="{name:route.name}"
      >{{ route.meta.display_name }}
      </b-button>

      <b-collapse
        v-for="(r,i) in route.children"
        :key="i"
        :id="`acc-${route.meta.slug}`"
        role="tabpanel">
        <menu-item :route="r" class="ml-1 sub_menu"></menu-item>
      </b-collapse>

    </div>
  </b-nav>
</template>

<script>
export default {
  name: "MenuItem",
  props: [
    'route'
  ],
}
</script>

<style scoped lang="scss">

.sub_menu {
  display: block;

  button {
    padding-top: 0;
  }
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

</style>