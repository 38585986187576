import axios from '@/js/axios.js'

export default {
  index ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.event_uuid) {
        axios.get(`/sensors/${payload.event_uuid}`)
          .then((response) => {
            commit('SET_SENSORS', response.data.sensors)
            resolve(response)
          })
          .catch((error) => { reject(error) })
        } else if (payload.account_id) {
          axios.get(`/sensors/account/${payload.account_id}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
        } else {
          axios.get(`/sensors/account/`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
        }
    })
  },
  snooze({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        `/sensors/${payload.event_uuid}/snooze`,
        payload
      ).then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  }
}