import axios from '@/js/axios.js'

export default {
  index ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      payload.sortBy = 'event_start'
      axios.post('/events', payload)
          .then((response) => {
              response.data.events.data.forEach(event => event.show = true);
              commit('SET_EVENTS', response.data.events.data)
              resolve(response)
          })
          .catch((error) => { reject(error) })
    })
  },
}