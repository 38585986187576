<template>

  <div id="app" class="w-full">

    <header id="header">
      <b-container>
        <b-row>
          <b-col>
            <b-navbar toggleable="lg" class="shadow bg-white">

              <b-button v-if="isAuthenticated" variant="transparent" v-b-toggle.sidebar class="mr-2">
                <span class="navbar-toggler-icon"></span>
              </b-button>

              <b-navbar-brand><img alt="Meshh" src="./assets/logo.svg"/></b-navbar-brand>

              <div v-if="isAuthenticated" @click="show = !show" class="ml-auto">

                <b-iconstack id="tooltip-icon" font-scale="2">
                  <b-icon-circle-fill stacked variant="white"></b-icon-circle-fill>
                  <b-icon-person-circle stacked></b-icon-person-circle>
                </b-iconstack>

                <b-tooltip :show.sync="show" target="tooltip-icon" placement="bottom" variant="secondary">
                  <p class="d-inline mr-3 text-lowercase" v-if="$store.state.auth.user.name">
                    {{ $store.state.auth.user.name }}</p>
                  <b-button variant="primary" @click="logout">Logout</b-button>
                </b-tooltip>

              </div>

            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
    </header>

    <main id="main">
      <b-container>
        <b-row>
          <b-col>
            <breadcrumbs></breadcrumbs>
            <user-feedback></user-feedback>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <vue-page-transition name="zoom">
              <router-view></router-view>
            </vue-page-transition>
          </b-col>
        </b-row>

      </b-container>
    </main>

    <side-nav v-if="isAuthenticated"></side-nav>

  </div>

</template>

<script>

import SideNav from "@/js/components/sidenav/SideNav"
import Breadcrumbs from "@/js/components/breadcrumbs/Breadcrumbs"
import passport from '@/js/http/requests/auth/passport/index.js'
import {mapGetters} from "vuex";

export default {
  name: 'App',
  data() {
    return {
      notify_id: null,
      show: false,
      loading: false,
    }
  },
  components: {
    SideNav,
    Breadcrumbs
  },
  computed: mapGetters({
    isAuthenticated: "auth/isAuthenticated"
  }),
  mounted: function () {
    /*
    this.$root.$on('axios_request', () => {
      this.loading = true;
    });
    this.$root.$on('axios_request_complete', () => {
      this.loading = false;
    });

     */
  },
  methods: {

    logout() {
      this.$store.dispatch('auth/logoutPassport')
          .then((response) => {
            this.$acl.change('user');
            this.$store.commit('clearState')
            this.$router.push({name: "login"});
            this.show = false;
          })
          .catch(error => {
            this.$store.commit('clearState')
            console.dir(error.message)
            this.$router.push({name: "login"});
            this.show = false;
          })
    },
  },
  async created() {

    // IF THE VERSION NUMBER FOR THE APP HAS CHANGED DELETE THIS USER
    if(this.$store.getters.appVersion != process.env.PACKAGE_VERSION){
      this.$store.state.package_version = process.env.PACKAGE_VERSION;
      this.$store.commit('clearState');
      this.$router.push({name: "login"}).catch(() => {});
    }

    passport.init(this);
  }

}

</script>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  height: 100vh;
}

.navbar-brand {
  width: 83px;
  opacity: 1;
  margin-top:-6px;
  max-width: 190px;
}

.navbar-toggler {
  padding: .1rem .5rem !important;
}


</style>
