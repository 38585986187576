import state from './moduleSensorState.js'
import mutations from './moduleSensorMutations.js'
import actions from './moduleSensorActions.js'
import getters from './moduleSensorGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
