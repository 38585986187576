import axios from '@/js/axios.js'

export default {
  index ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/clusters/${payload.event_id}`, payload)
        .then((response) => {
          commit('SET_CLUSTERS', response.data.clusters)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}