<template>
  <nav
    v-if="$router.currentRoute.path != '/'"
    id="Breadcrumbs" class="mb-2 ml-2">
    <b-nav v-if="breadcrumbs.length > 0">
      <b-nav-item
        v-for="(r,i) in breadcrumbs"
        :key="i"
        :to="{name:r.name}">{{r.display_name}}<span class="ml-2">></span>
      </b-nav-item>
      <b-nav-item>{{ $router.currentRoute.meta.display_name }}</b-nav-item>
    </b-nav>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Breadcrumbs",
  data() {
    return {
      breadcrumbs: []
    }
  },
  watch: {
    $route(to, from) {
      this.breadcrumbs = (this.$router.currentRoute.meta.breadcrumbs) ? this.$router.currentRoute.meta.breadcrumbs : [];
      //console.dir(this.breadcrumbs);
      this.$forceUpdate();
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>

#Breadcrumbs {
  .nav-link{
    padding: .1rem .25rem;
  }
}

</style>