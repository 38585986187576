import state from './moduleClusterState.js'
import mutations from './moduleClusterMutations.js'
import actions from './moduleClusterActions.js'
import getters from './moduleClusterGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
