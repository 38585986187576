<template>

  <b-card
    v-if="route.meta.visible"
    no-body
    class="border-0 mb-2">

    <b-card-header
      v-can="route.meta.acl"
      header-tag="header" class="p-0 border-0" role="tab">
      <b-button
        variant="primary"
        block
        ref="button-surveys"
        class="text-left menutoggle w-100"
        v-b-toggle="route.meta.display_name">{{ route.meta.display_name }}
        <b-icon v-if="route.meta.icon"
                font-scale="1.5"
                :icon="route.meta.icon"
                class="m-0 float-right"></b-icon>
      </b-button>
    </b-card-header>

    <b-collapse :id="route.meta.display_name" class="mt-1" accordion="main-menu-accordion" :visible="route.meta.open">
      <nav class="mt-1">
        <menu-item
          v-for="(r,i) in route.children"
          :key="i"
          :id="`acc-${route.meta.slug}`"
          role="tabpanel"
          :route="r"></menu-item>
      </nav>
    </b-collapse>

  </b-card>
</template>

<script>

export default {
  name: "AccordionMenu",
  props: {
    route: Object
  },
}
</script>

<style scoped>

</style>