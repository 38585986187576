const mutations ={
    muteZone(state, sensor) {
        state.muted_sensors.push(sensor.id);
    },
    unMuteZone(state, sensor) {
        let index = state.muted_sensors.indexOf(sensor.id);
        if (index > -1) {
            state.muted_sensors.splice(index, 1);
        }
    },
    addNotification(state, notification) {
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = today.getHours() + ":" + today.getMinutes();
        notification.date = date;
        notification.time = time;
        state.notifications.unshift(notification);
        if(state.notifications.length > 20) state.notifications.pop();
    },
    deleteNotification(state, notification) {
        let index = state.notifications.indexOf(notification);
        if (index > -1) state.notifications.splice(index, 1);
    },
    updateNotificationFrequency(state, notification_frequency){
        state.notification_frequency = notification_frequency * 60 * 1000;
    },
    clearState(state) {
        this.commit('auth/DELETE_USER')
        this.commit('event/CLEAR_STATE')
        //Cookie.remove('cloudKey')
    }
}

export default mutations