<script>

import {Line, mixins} from 'vue-chartjs'
import {mapGetters} from "vuex";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  data() {
    return {
      options: {
        elements: {
          line: {
            tension: 0, // disables bezier curves
            stepped: true,
            borderDash: [],
            borderWidth: 2,
            fill: false,
          },
        },
        legend: {
          labels: {
            fontColor: 'rgba(255, 255, 255, 0.5)',
            boxWidth: 20
          },
        },
        scaleFontColor: '#ffffff',
        responsive: true,
        //maintainAspectRatio: false,
        lineTension: 1,
        scales: {
          yAxes: [{
            gridLines: {
              display: false,
            },
            id: 'Percentage',
            type: 'linear',
            position: 'left',
            ticks: {
              fontColor: 'rgba(255, 255, 255, .6)',
              fontSize: 15,
              min: 0,
              max: 100,
              stepSize: 20,
            }

          }, {
            gridLines: {
              display: false,
              color: 'rgba(255, 255, 255, 0.02)',
            },
            id: 'Devices',
            type: 'linear',
            position: 'right',
            ticks: {
              display: false,
              fontColor: 'rgba(255, 255, 255, .6)',
              fontSize: 15,
              min: 0,
              max: 100,
              stepSize: 5,
            }
          }],
          xAxes: [{
            gridLines: {
              color: 'rgba(255, 255, 255, 0.02)',
            },
            ticks: {
              fontColor: 'rgba(255, 255, 255, 0.6)',
              fontSize: 14,
              min: 0,
              padding: 10,
              autoSkip: true,
              maxTicksLimit: 10
            }
          }]
        }
      }
    }
  },
  props: {
    chartData: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      isStaffMember: 'auth/isStaffMember',
    }),
    lastPointPosition: function () {
      return this.getLastPointPosition();
    }
  },
  methods: {
    checkDevicesData() {
      // calculate a rational index for the devices axis
      if (this.isStaffMember && this.chartData.datasets[2].data.length > 0) {

        let end_index = Math.max(...this.chartData.datasets[2].data);
        end_index = Math.ceil(end_index / 10) * 10;

        let step = end_index / 10;
        step = Math.ceil(step / 10) * 10;

        this.options.scales.yAxes[1].ticks.max = end_index;
        this.options.scales.yAxes[1].ticks.stepSize = step;

      }
    },
    getPointPosition(index) {

      let meta, point, rectangle;
      meta = this.$data._chart.getDatasetMeta(0);

      let pos = {x: 0, y: 0};
      if (meta.data.length > 0) {
        rectangle = this.$data._chart.canvas.getBoundingClientRect();
        point = meta.data[index].getCenterPoint();
        pos.x = Math.floor(point.x);
        pos.y = Math.floor(point.y - rectangle.top);
      }

      return pos;
    },
    getLastPointPosition() {

      if (this.$data._chart.config.data.datasets.length > 0) {

        let meta = this.$data._chart.getDatasetMeta(0);
        if (meta.data.length > 0) {
          return this.getPointPosition(meta.data.length - 1);
        } else {
          return {x: 0, y: 0};
        }

      } else {
        return {x: 0, y: 0};
      }
    }
  },
  watch: {
    chartData: function (data) {
      this.checkDevicesData();
      this.renderChart(this.chartData, this.options);

    },
  },
  mounted() {
    if (this.isStaffMember) {
      this.options.scales.yAxes[1].gridLines.display = true;
      this.options.scales.yAxes[1].ticks.display = true;
      this.checkDevicesData();
    }
    this.renderChart(this.chartData, this.options);
  }
}

</script>

<style scoped>

</style>