import axios from '@/js/axios.js'

export default {
  index({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/surveys', payload)
        .then((response) => {
          commit('SET_SURVEYS', response.data.surveys)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  show({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveys/${payload.slug}/show`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}