const actions = {
    setZones ({ commit, state }, sensors) {

        let sensors_down = false;
        let threshold_exceeded = false;

        for(let index in sensors) {

            let sensor = sensors[index];

            let muted = state.muted_sensors.indexOf(sensor.id);
            if (muted < 0) {
                if (sensor.is_up == 0) sensors_down = true;
                if (sensor.threshold == 100) threshold_exceeded = true;
            }

        }

        if(sensors_down) this.$notify.noteZonesDown();
        if(threshold_exceeded) this.$notify.noteZonesThresholdExceeded();

        commit('setZones', sensors);

    }
}

export default actions