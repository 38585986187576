import state from './moduleSurveysState.js'
import mutations from './moduleSurveysMutations.js'
import actions from './moduleSurveysActions.js'
import getters from './moduleSurveysGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
