<template>
  <div>
    <b-sidebar

      @change="change"
      id="sidebar"
      bg-variant="white"
      width="250px"
      aria-labelledby="sidebar"
      no-header
      shadow
      backdrop backdrop-variant="transparent">
      <template v-slot:default="{ hide }">
        <div class="p-3">

          <img class="logo" src="@/assets/logo.svg"/>

          <div role="tablist" class="mt-4 mb-5">
            <div v-for="(r,i) in $router.options.routes" :key="i" :route="r">
              <accordion-menu v-if="!r.name"
                              :key="i"
                              :route="r"></accordion-menu>
              <menu-item v-else
                :route="r"></menu-item>
            </div>
          </div>

          <b-button @click="hide">Close menu</b-button>

        </div>
        <b-img src="@/assets/meshh-lattice.png" class="sidebar-b-image w-100 align-bottom"></b-img>
      </template>

    </b-sidebar>
  </div>
</template>

<script>

import AccordionMenu from './AccordionMenu';

export default {
  name: "SideNav",
  props: {
    request: {
      type: String,
      default: ''
    }
  },
  components: {
    AccordionMenu
  },
  data() {
    return {
      container: document.getElementById("app"),
      visible: false
    }
  },
  methods: {
    change(visible) {
      this.visible = visible;
      (visible) ? this.container.classList.add("sidebar-active") : this.container.classList.remove("sidebar-active");
    },
  },
}
</script>

<style lang="scss">

#sidebar {

  .logo {
    width: 130px;
    opacity:.4;
  }

  .sidebar-b-image {
    position: absolute;
    bottom: 0;
    height: 60%;
    width: 100%;
    object-fit: cover;
    opacity: .15;
    pointer-events: none;
  }

  .b-sidebar-body {
    margin-top: 15px;
  }

}


</style>
