import axios from '../../http/axios/index.js'

export default {
  SET_BEARER (state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  },
  DELETE_USER(state) {
    state.user=null
    state.accessToken=null
    state.tokenExpiry=null
  },
  SET_ACCESS_TOKEN(state, payload){
    state.accessToken = payload.token
    state.tokenExpiry = payload.expiry
  },
  UPDATE_USER_INFO (state, payload) {
    if(!state.user) state.user = {};
    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.user
        state.user[property] = payload[property]
      }
    }
  }
}
