export default {
  SET_EVENTS (state, events) {
    if(events.length > 0) events.forEach(event => event.show = true);
    state.events = events
  },
  SET_SELECTED_EVENT(state, event){
    state.selected_event = event;
  },
  CLEAR_STATE(state) {
    state.date_from = null
    state.date_to = null
    state.selected_event = null
    state.search_filter = null
  }
}