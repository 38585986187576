import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from './router'
import store from './store/store'

Vue.use(AclInstaller)

// custom directive for v-can
Vue.directive('can', function (el, bindings, vnode) {
  
  if (!vnode.context.$store.getters['auth/hasScope'](bindings.value)) {
    el._originalStyle = (el._originalStyle)? el._originalStyle : el.style.display;
    el.style.display = "none";
    return false;
  } else {
    let display = el._originalStyle = (el._originalStyle)? el._originalStyle : el.style.display;
    el.style.display = display;
    return true;
  }
})

export default new AclCreate({
    initial  : (store.state.auth.user && store.state.auth.user.role)? store.state.auth.user.role : 'user',
    notfound : '/page-not-found',
    router,
    acceptLocalRules : true,
    globalRules: {
        super_admin  : new AclRule('super_admin').generate(),
        admin  : new AclRule('admin').or('super_admin').generate(),
        staff  : new AclRule('staff').or('admin').or('super_admin').generate(),
        client_administrator : new AclRule('client_administrator').or('staff').or('admin').or('super_admin').generate(),
        client_user : new AclRule('client_user').or('client_administrator').or('staff').or('admin').or('super_admin').generate(),
        operations : new AclRule('operations').or('client_user').or('client_administrator').or('admin').or('staff').or('super_admin').generate(),
        user : new AclRule('user').or('operations').or('client_user').or('client_administrator').or('admin').or('staff').or('super_admin').generate(),
    }
})
