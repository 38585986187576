import state from './moduleThresholdState.js'
import mutations from './moduleThresholdMutations.js'
import actions from './moduleThresholdActions.js'
import getters from './moduleThresholdGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
