export default {
  lastSeen: (state) =>{
    if(state.selected_survey.last_seen) {
      let date = new Date(state.selected_survey.last_seen.replace(/-/g, '/') + ' UTC');
      return date.toDateString() + ' ' + date.toLocaleTimeString();
    } else {
      return null;
    }
  },
}
