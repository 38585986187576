import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { EncryptStorage } from "encrypt-storage"
import * as uuid from "uuid"
import Cookie from "js-cookie"

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import moduleEvent from './event/moduleEvent.js'
import moduleSensor from './sensors/moduleSensor.js'
import moduleCluster from './clusters/moduleCluster.js'
import moduleThreshold from './threshold/moduleThreshold.js'
import moduleSurvey from './surveys/moduleSurveys.js'
import moduleAnalyticsReporting from "@/js/store/analytics/reporting/moduleAnalyticsReporting";

let cookieName = 'cloudKey'
let cookieValue = Cookie.get(cookieName)
let encryptionToken = (!cookieValue)?uuid.v4():cookieValue.split('|')[0]
let ls = new EncryptStorage(encryptionToken)

// Store the encryption token in a secure  cookie.
let secure = process.env.NODE_ENV != 'development'
if (!cookieValue) {
  Cookie.set(cookieName, encryptionToken + '|0', { secure: secure })
} else {
  if (cookieValue.split('|')[1] == 1) {
    Cookie.set(cookieName, encryptionToken + '|1', { secure: secure, expires: 30 })
  }
}
const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: {
    getItem: (key) => {
      try {
        return ls.getItem(key)
      } catch (e) {
        return null
      }
    },
    setItem: (key, value) => {
      ls.setItem(key, value)
    },
    removeItem: (key) => {
      ls.removeItem(key)
    }
  },
  reducer: state => ({
    package_version: state.package_version,
    auth:{
      user: state.auth.user,
      accessToken: state.auth.accessToken,
      tokenExpiry: state.auth.tokenExpiry,
    },
    analytics_reporting:{
      reporting_data_cache: state.analytics_reporting.reporting_data_cache,
    },
    muted_sensors: state.muted_sensors,
    notification_frequency: state.notification_frequency,
    event: {
      date_from: state.event.date_from,
      date_to: state.event.date_to,
      search_filter: state.event.search_filter,
      selected_event: state.event.selected_event,
      account_id: state.event.account_id
    }
    /*
    threshold:{
      threshold_data_cache: state.threshold.threshold_data_cache,
    },

     */
  })
});

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth: moduleAuth,
    event: moduleEvent,
    sensors: moduleSensor,
    clusters: moduleCluster,
    threshold: moduleThreshold,
    surveys: moduleSurvey,
    analytics_reporting: moduleAnalyticsReporting
  },
  plugins: [vuexLocalStorage.plugin]
})

